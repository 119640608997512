import React from "react"
import PropTypes from "prop-types"
import ServicesRow from "./components/ServicesRow"
import { defaultServicesList as defaultData } from "../../../../../defaultData"

const index = ({ title, serviceList }) => {
  const getPrices = service => {
    let prices = []
    for (const item in service) {
      if (item.includes("feature") && item !== "features") {
        if (service[item]) {
          prices.push(service[item])
        }
      }
    }
    return prices
  }
  let shapedList
  if (serviceList && serviceList.length) {
    shapedList = serviceList.map(service => ({
      id: service.id,
      title: service.title,
      picture: service.image.fluid.src,
      content: service.about.about,
      features: JSON.parse(service.features.raw),
      prices: getPrices(service),
    }))
  } else {
    shapedList = [...defaultData]
  }
  return (
    <section className="bg-secondary">
      <div className="container">
        <div className="row mb64 mb-xs-24">
          <div className="col-md-10 col-md-offset-1 col-sm-12 text-center">
            {title && <h3>{title}</h3>}
          </div>
        </div>
        <ServicesRow serviceList={shapedList} />
      </div>
    </section>
  )
}

index.propTypes = {
  title: PropTypes.string,
  serviceList: PropTypes.arrayOf(PropTypes.object),
}

export default index
