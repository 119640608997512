import React from "react"
import PropTypes from "prop-types"
import ServiceRate from "./ServiceRate"

const ServicesRow = ({ serviceList }) => {
  return (
    <div className="row">
      {serviceList &&
        serviceList.map(service => (
          <ServiceRate key={service.id} {...service} />
        ))}
    </div>
  )
}

ServicesRow.propTypes = {
  serviceList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      picture: PropTypes.string,
      content: PropTypes.string,
      prices: PropTypes.arrayOf(PropTypes.string),
      features: PropTypes.object,
    })
  ),
}

export default ServicesRow
