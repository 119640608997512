import React from "react"
import PropTypes from "prop-types"
import Prices from "./Prices"

const ServiceRate = ({ title, picture, content, prices }) => {
  return (
    <div className="col-sm-4">
      <img alt={title} className="mb24" src={picture} />

      <h4 className="mb8">{title}</h4>

      <div className="mb4">
        {content}
        {prices && <Prices priceList={prices} />}
      </div>
    </div>
  )
}

ServiceRate.propTypes = {
  title: PropTypes.string,
  picture: PropTypes.string,
  content: PropTypes.string,
  prices: PropTypes.arrayOf(PropTypes.string),
}

export default ServiceRate
