export const defaultPost = {
  thumbnail: "/img/bannertalk.jpg",
  title: "A simple image post for starters",
  link: "/",
  iframe: `<iframe
  class="embed-responsive-item"
  src="https://www.youtube.com/embed/cW7WIvSxB4Q"
  title="YouTube video player"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen="allowfullscreen"
></iframe>`,
  publishedDate: "September 23, 2015",
  snippet: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem
  accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
  illo inventore veritatis et quasi architecto beatae vitae dicta sunt
  explicabo.`,
  category: "Lifestyle",
}
export const defaultPosts = [
  {
    thumbnail: "/img/bannertalk.jpg",
    title: "A simple image post for starters",
    link: "/",
    iframe: `<iframe
  class="embed-responsive-item"
  src="https://www.youtube.com/embed/cW7WIvSxB4Q"
  title="YouTube video player"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen="allowfullscreen"
></iframe>`,
    publishedDate: "September 23, 2015",
    snippet: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem
  accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab.`,
    category: "Lifestyle",
  },
  {
    thumbnail: "/img/bannertalk.jpg",
    title: "A simple image post for starters",
    link: "/",
    publishedDate: "September 23, 2015",
    snippet: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem
  accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab.`,
    category: "Lifestyle",
  },
  {
    thumbnail: "/img/bannertalk.jpg",
    title: "A simple image post for starters",
    link: "/",
    iframe: `<iframe
  class="embed-responsive-item"
  src="https://www.youtube.com/embed/cW7WIvSxB4Q"
  title="YouTube video player"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen="allowfullscreen"
></iframe>`,
    publishedDate: "September 23, 2015",
    snippet: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem
  accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab.`,
    category: "Lifestyle",
  },
]

export const defaultPortfolio = [
  {
    src: "img/port/1.jpg",
    title: "Graduation",
    subtitle: "Graduation / Life",
    thumb: "img/port/1.jpg",
    full: "img/port/1.jpg",
  },
  {
    src: "img/port/15.jpg",
    title: "Portrait",
    subtitle: "Portrait / Life",
    thumb: "img/port/15.jpg",
    full: "img/port/15.jpg",
  },
  {
    src: "img/port/16.jpg",
    title: "Events",
    subtitle: "Weddings / Events",
    thumb: "img/port/16.jpg",
    full: "img/port/16.jpg",
  },
  {
    src: "img/port/6.jpg",
    title: "Sports",
    subtitle: "Sports",
  },
  {
    src: "img/port/17.jpg",
    title: "Events",
    subtitle: "Events / Life",
  },
  {
    src: "img/port/11.jpg",
    title: "Portrait",
    subtitle: "Portrait / Life",
  },
  {
    src: "img/port/2.jpg",
    title: "Graduation",
    subtitle: "Graduation / Life",
  },
  {
    src: "img/port/10.jpg",
    title: "Sports",
    subtitle: "Sports",
  },
  {
    src: "img/port/12.jpg",
    title: "Portrait",
    subtitle: "Portrait / Life",
  },
  {
    src: "img/port/9.jpg",
    title: "Sports",
    subtitle: "Sports",
  },
  {
    src: "img/port/5.jpg",
    title: "Graduation",
    subtitle: "Senior Portraits",
  },
  {
    src: "img/port/13.jpg",
    title: "Portrait",
    subtitle: "Portrait / Life",
  },
  {
    src: "img/port/20.jpg",
    title: "Events",
    subtitle: "Weddings / Events",
  },
  {
    src: "img/port/8.jpg",
    title: "Sports",
    subtitle: "Sports",
  },
  {
    src: "img/port/4.jpg",
    title: "Graduation",
    subtitle: "Graduation",
  },
  {
    src: "img/port/14.jpg",
    title: "Graduation",
    subtitle: "Senior Portraits",
  },

  {
    src: "img/port/3.jpg",
    title: "Portrait",
    subtitle: "Portrait / Life",
  },
  {
    src: "img/port/19.jpg",
    title: "Events",
    subtitle: "Weddings/Events",
  },
  {
    src: "img/port/21.jpg",
    title: "Events",
    subtitle: "Concerts / Events",
  },

  {
    src: "img/port/23.jpg",
    title: "Events",
    subtitle: "Concerts / Events",
  },
]

export const defaultFeatured = [
  {
    thumbnail: "/img/work2.jpg",
    title: "Thee Stallion",
    subtitle: "Events / Concert",
    link: "/featured/megan",
  },
  {
    thumbnail: "/img/work1.jpg",
    title: "Senior Shoots",
    link: "/featured/champagne",
  },
  {
    thumbnail: "/img/work3.jpg",
    title: "Weddings",
    subtitle: "Events",
    link: "/featured/wedding",
  },
  {
    thumbnail: "/img/work4.jpg",
    title: "Go Hokies",
    subtitle: "Events / Sports",
    link: "/featured/sports",
  },
]

export const defaultServicesList = [
  {
    picture: "/img/rates 2.jpg",
    title: "Service",
    content:
      "I really love senior shoots. It crystallizes every moment spent in this great institution. My packages include multiple locations and outfit changes.",
    prices: [
      "Senior Photos - $100 per hour",
      "Group portrait sessions for 3-6 persons start at $85 per person, booked in 1.5-2.5 hour sessions.",
    ],
  },
  {
    picture: "/img/rates 1.jpg",
    title: "LOVE",
    content:
      "I love shooting at weddings and engagements. Its evokes pure joy in the lovers and the witnesses and brings the best and most important memories. I will be honored to be your photographer, afterall (your) love is in the air.",
    prices: ["Engagement Photos - $150 per hour", "Weddings - Start at $1000"],
  },

  {
    picture: "/img/rates 0.jpg",
    title: "PORTRAITS",
    content:
      "I will be delighted to help you save memories from whatever occasion. It may be as formal as a LinkedIn portrait or as joyful as a family and friends reunion. I provide packages that mirror your needs and will deliver hi-res digital pictures at your convenience.",
    prices: [
      "Headshots for Linkedin - $40 for 20 minutes",
      "Regular Portrait Shoots - $80 per hour",
      "Organization Group Portrait Shoots - Start at $150",
    ],
  },
]
