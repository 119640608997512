import React from "react"
import PropTypes from "prop-types"

const Prices = ({ priceList }) => {
  return (
    <ul className="lead" data-bullet="ti-plus">
      {priceList && priceList.map(price => <li key={price}>{price}</li>)}
    </ul>
  )
}

Prices.propTypes = {
  price: PropTypes.string,
  priceList: PropTypes.arrayOf(PropTypes.string),
}

export default Prices
